.Home_main__nLjiQ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-height: 81vh;
}

.Home_card___LpL1 {
    padding: 1rem 1.2rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
}

.Home_card___LpL1 span {
    display: inline-block;
    transition: transform 200ms;
}

.Home_card___LpL1 h2 {
    font-weight: 600;
    margin-bottom: 0.7rem;
}

.Home_card___LpL1 p {
    margin: 0;
    opacity: 0.6;
    font-size: 0.9rem;
    line-height: 1.5;
    max-width: 30ch;
}

.Home_center__4BFgC {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
}

.Home_center__4BFgC::before {
    background: var(--secondary-glow);
    border-radius: 50%;
    width: 480px;
    height: 50px;
    margin-left: -400px;
}

.Home_center__4BFgC::after {
    background: var(--primary-glow);
    width: 240px;
    height: 50px;
    z-index: -1;
}

.Home_center__4BFgC::before,
.Home_center__4BFgC::after {
    content: '';
    left: 50%;
    position: absolute;
    filter: blur(45px);
    transform: translateZ(0);
}

.Home_logo__27_tb {
    position: relative;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
    .Home_card___LpL1:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }

    .Home_card___LpL1:hover span {
        transform: translateX(4px);
    }
}

@media (prefers-reduced-motion) {
    .Home_card___LpL1:hover span {
        transform: none;
    }
}

/* Mobile */
@media (max-width: 700px) {
    .Home_content__Zy02X {
        padding: 4rem;
    }

    .Home_card___LpL1 {
        padding: 1rem 2.5rem;
    }

    .Home_card___LpL1 h2 {
        margin-bottom: 0.5rem;
    }

    .Home_center__4BFgC::before {
        transform: none;
        height: 50px;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
    .Home_grid__GxQ85 {
        grid-template-columns: repeat(2, 50%);
    }
}

@keyframes Home_rotate____XsI {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d9825c';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d9825c';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_d9825c {font-family: '__Inter_d9825c', '__Inter_Fallback_d9825c';font-style: normal
}

